<template>
	<li class="m-2 is-pulled-left has-text-centered" v-if="truck_job.stops.length > 0">
		<div class="has-text-left">
			<i class="fas fa-flag-checkered mr-2"></i>
			<span v-if="$parent.$parent.$parent.warehouses_parameters.length > 0" v-html="getTruckName(truck_job.details.destination, $parent.$parent.$parent.warehouses_parameters)"></span>
			<span v-else>
				<span v-if="$parent.$parent.$parent.event_details.Address_Customer">
					{{$parent.$parent.$parent.event_details.Address_Customer.Street}}, 
					{{$parent.$parent.$parent.event_details.Address_Customer.Zip}}
					<!-- <br/> -->
					{{$parent.$parent.$parent.event_details.Address_Customer.City}}, 
					{{$parent.$parent.$parent.event_details.Address_Customer.Country.Caption}}
				</span> <br>
				<span class="is-flex is-align-items-center">
					<i class="fas fa-xs fa-exclamation-triangle has-text-danger-dark mr-1"></i>
					<p>Please specify a delivery address</p>
				</span>
			</span>
			<ul class="has-text-left job-materials-table-wrap custom_scrollbar-1">
				<li v-for="(mat, mix) in truck_job.Qty"
					class="has-border p-1 has-background-white" :class="{'is-hidden': getLeftAmount(mat) == 0}"
					:key="mix">{{mat.description}} 
					<span class="is-pulled-right ml-3 has-text-weight-bold">
						{{getLeftAmount(mat)}}
					</span>
				</li>
			</ul>
		</div>
	</li>
</template>

<script>
	import { EventBus } from '@/event-bus';
	export default{
		props: ["truck_job"],
		methods: {
			getLeftAmount(m){ 
				
				let a = this.truck_job.Qty.filter(mat => mat.IdST == m.IdST)[0][this.truck_job.status_id == 0 ? "Qty" : "loaded_amount"]
				this.truck_job.stops.map(s => {
					s.Qty.map(mat => {
						if(mat.IdST == m.IdST) a -= mat.Qty
					})
				})
				
				return a >= 0 ? a : "ERROR"
			},
			saveFinalDestinantionLoading(){
				alert('should save now')
			},
			getTruckName(id, list){
				let a = list.filter(l => l.id == id)
				if(a.length == 1) {
					let fields = JSON.parse(a[0].form_value)
					let truck_name = fields[0].value
					return '<b>' + truck_name + '</b><br><small>' +( a[0].data ? a[0].data.name : '') + '</small>'
				}
				else return id
			},
		},
		created: function() {
			EventBus.$on('save_materials', this.saveFinalDestinantionLoading);
		},

		destroyed: function() {
			EventBus.$off('save_materials', this.saveFinalDestinantionLoading);
		}
	}
</script>

<style scoped>

</style>

